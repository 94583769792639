import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

import img1 from "../images/climatisation1.jpg"
import img2 from "../images/climatisation2.jpg"
import img3 from "../images/climatisation3.jpg"
import img4 from "../images/climatisation4.jpg"
import img5 from "../images/climatisation5.jpg"

const ClimatisationPage  = () => (
    <Layout>
        <SEO 
            page="climatisation-lyon"
            title="Climatisation à Lyon, dépannage et installation"
            description="L'équipe d'Air Water System, installateurs de climatisation à Lyon, se déplace dans toute la région pour vous conseiller, réaliser les travaux et dépanner vos installations."
            keywords={["Dépannage", "Entreprise", "Société", "Artisan", "Réparation","Entretien", "Maintenance", "Installation", "Energie thermique", "Réversible","Ventilation","Extérieur","Intérieur","Système", 
            "climatisation","pompe à chaleur","ventilation","climatiseur","clim","frigorifique","climatiseurs","pompes à chaleur","pompes","fluide","refroidissement","condensation","réversible","réfrigérant",
            "fluide frigorigène","thermique","températures","frigorigène","frigorifiques , système de climatisation","rafraîchissement","chaleur air","fluides","systèmes de climatisation","air chaud","pompe",
            "extérieure","rafraîchir","humidité","thermodynamique","unité extérieure","économies d énergie","réfrigération","climatisations","ambiant","chauffer","réversibles","installateur","chauffage et climatisation",
            "climatiser","qualité de l air","air ambiant","air conditionné","refroidir","évacuation","réversible","air extérieur","air frais","unité intérieure","énergétiques","chaleur","frigorigènes","puissance frigorifique",
            "dépannage","mural","énergétique","froid","climatiseur mobile","climatiseur réversible","fraîcheur","air intérieur","consommation","consommation énergétique","climatiseur fixe","installations","niveau sonore",
            "air froid","débit d air","température","thermiques"]}
        />
        <section className="section">
            <div className="container">
                <h1 className="title">Air Water System, installateur de climatisation à Lyon</h1>
                <h2 className="subtitle">Dépannage et installation de climatisation à Lyon</h2>
                <div class="content">
                    <p>
                        Vous rêvez d’un air ambiant sain, à la <strong>température agréable</strong>, même lorsque l’air extérieur
                        évoque une fournaise ? Vous souhaitez faire installer un <strong>climatiseur</strong> ou une <strong>pompe à
                        chaleur</strong>, tout en maîtrisant votre consommation énergétique ? L’équipe d’Air Water System,
                        installateurs de <strong>climatisation à Lyon</strong>, se déplace dans toute la région pour vous conseiller,
                        réaliser les travaux et dépanner vos installations le cas échéant.
                    </p>
                </div>
                <div class="content">
                <Link to="#climatisation-lyon">Climatisation à Lyon</Link>
                </div>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <h2 className="title is-4">Pourquoi et comment climatiser votre logement ?</h2>
                <div className="content">
                    <div className="columns is-vcentered">
                        <div className="column">
                            <p> 
                                Le principe de la <strong>« clim »</strong> est simple : il s’agit de garantir une température constante de l’air
                                intérieur, quelle que soit la chaleur dehors, le tout sans consommation d’électricité
                                excessive et en maintenant une <strong>qualité de l’air optimale</strong>. Pour cela, il existe plusieurs
                                options.
                            </p>
                            <p>
                                La <strong>climatisation traditionnelle</strong> permet de rafraîchir l’air ambiant grâce à un fluide frigorigène
                                présent dans le <strong>climatiseur</strong>. Celui-ci capture les calories de l’air chaud et assure leur
                                évacuation extérieure. S’il s’agit d’une <strong>climatisation réversible</strong>, elle peut également servir de
                                chauffage d’appoint, sur le même principe qu’une <strong>pompe à chaleur</strong>.
                            </p>
                            <p>
                                À l’inverse des climatiseurs, les <strong>pompes à chaleur</strong> permettent de réaliser des économies
                                d’énergie sans sacrifier votre <strong>confort thermique</strong>. Conçus pour récupérer la chaleur de l’air
                                extérieur et utiliser les calories obtenues pour chauffer l’air intérieur, ces appareils existent
                                également en version <strong>réversible</strong> : ils peuvent alors assurer le rafraîchissement de votre
                                logement. 
                            </p>
                        </div>
                        <div className="column">
                            <figure class="image">
                                <img alt="Climatisation Lyon" src={img1}></img>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <h2 className="title is-4">Un installateur qualifié pour votre système de climatisation à Lyon</h2>
                <p className="content">
                    Spécialisée en <strong>chauffage</strong> et <strong>climatisation</strong>, l’entreprise <strong>Air Water System</strong> est installateur de
                    climatisation à Lyon et dans les environs depuis de nombreuses années. Grâce à notre
                    expérience et à nos connaissances, nous sommes en mesure de vous proposer différents
                    systèmes d’air conditionné :
                    <ul>
                        <li>Climatiseur fixe</li>
                        <li>Climatiseur mobile</li>
                        <li>Climatiseur réversible</li>
                        <li>Pompes à chaleur simples ou réversibles...</li>
                    </ul>
                </p>
                <p className="content">
                    Quelles que soient les installations que vous nous confiez, vous êtes assuré de disposer d’un
                    air frais et sain en toute saison, de dépenses énergétiques contrôlées et d’un <strong>confort
                    thermique</strong> parfait. 
                </p>
                <div className="columns is-centered">
                    <div className="column is-4 has-text-centered">
                        <img width="350" height="250" alt="Installation climatisation Moins" src={img2}></img>
                    </div>
                    <div className="column is-4 has-text-centered">
                        <img width="350" height="250" alt="Installation climatisation Moins" src={img3}></img>
                    </div>
                </div>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <div className="columns is-vcentered">
                    <div className="column is-9">
                        <h2 className="title is-4">Dépannage de climatiseurs : faites appel à des experts</h2>
                        <p className="content">
                            Votre climatiseur ne fournit plus d’air froid ? Votre <strong>consommation électrique</strong> a augmenté
                            sans raison apparente ? Vous avez constaté la présence de condensation ou d’humidité
                            autour de votre <strong>appareil</strong> ? La ventilation ne semble plus fonctionner correctement ? Le
                        </p>
                        <p className="content">
                            niveau sonore est plus important que d’habitude ? Pour tous ces soucis et bien d’autres,
                            n’hésitez pas à contacter les <strong>professionnels d’Air Water System</strong>. Nous nous déplaçons dans
                            les meilleurs délais pour diagnostiquer la panne et réparer votre <strong>climatisation à Lyon</strong> et
                            dans les environs.
                        </p>
                    </div>
                    <div className="column is-3">
                        <img alt="Dépannage climatisation Lyon" src={img4}></img>
                    </div>
                </div>
            </div>
        </section>
        <section className="section" id="climatisation-lyon">
            <div className="container">
                <h2 className="title is-4">Entretien et maintenance de systèmes de climatisation à Lyon</h2>
                <p className="content">
                    Pour garantir le bon fonctionnement de votre <strong>climatiseur mural</strong> ou de votre pompe à
                    chaleur, il est indispensable de confier son contrôle à des professionnels agréés. Notre
                    équipe se charge de toutes les <strong>vérifications</strong>, ainsi que des éventuels réglages et réparations :
                    <ul>
                        <li>Contrôle du débit d’air</li>
                        <li>Qualité du fluide frigorifique</li>
                        <li>Fonctionnement des systèmes thermodynamiques</li>
                        <li>Contrôle des unités intérieures et extérieures des PAC</li>
                        <li>Vérification des performances thermiques...</li>
                    </ul>
                </p>
                <p className="content">
                    Pour installer, entretenir ou dépanner vos <strong>climatisations à Lyon</strong>, une seule adresse : <strong>Air
                    Water System</strong>.
                </p>
                <p className="content">
                    <Link to="/">Entreprise de plomberie dans le Rhône.</Link>
                </p>
            </div>
        </section>
        <section className="section" id="climatisation-lyon">
            <div className="container">
                <h2 className="title is-4">Installation de climatisation réversible à Lyon</h2>
                <p className="content">
                    Air Water System, expert en chauffage, plomberie et climatisation à Lyon, réalise
                    pour vous tous travaux de confort thermique. Nous installons notamment votre
                    système de clim réversible à Lyon et dans toute la région.
                </p>
                <p className="content">
                    Que vous habitiez en appartement ou en maison individuelle, nous vous proposons
                    une large gamme de climatiseurs réversibles, ainsi que des pompes à chaleur. Ces
                    dernières, en plus d’être écologiques, permettent à l’heure actuelle de basculer du
                    mode « chauffage » au mode « rafraîchissement » en fonction de vos besoins. Nous
                    intervenons également auprès des professionnels.
                </p>
                <p className="content">
                    N’hésitez pas à faire appel à Air Water System pour votre installation de climatisation
                    réversible à Lyon.
                </p>
            </div>
        </section>
    </Layout>
)

export default ClimatisationPage;
